import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { Provider } from "react-redux";

import SEO from "../components/atoms/SEO/SEO";
import { setupAnalytics, trackPageView } from "../lib/analytics";
import store from "../redux/store";
import "./_app.css";

setupAnalytics();

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    Sentry.init({
      enabled: process.env.NODE_ENV === "production",
      dsn: "https://a61455e0dd1b4c529e99de52f478b0a7@o757173.ingest.sentry.io/5792283",
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }, []);

  useEffect(() => {
    const handleRouteChange = () => trackPageView();

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, []);
  return (
    <Provider store={store}>
      <SEO />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...pageProps} />
    </Provider>
  );
}

export default MyApp;
