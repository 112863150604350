import getAPIEndpoint from "./getAPIEndpoint";

// eslint-disable-next-line no-undef
const defaultFetchOptions: RequestInit = {
  mode: "cors",
  cache: "no-cache",
  credentials: "include",
  headers: {
    "Content-Type": "application/json",
  },
};

// eslint-disable-next-line no-shadow
export enum MethodEnum {
  // eslint-disable-next-line no-unused-vars
  GET = "GET",
  // eslint-disable-next-line no-unused-vars
  POST = "POST",
  // eslint-disable-next-line no-unused-vars
  PUT = "PUT",
  // eslint-disable-next-line no-unused-vars
  DELETE = "DELETE",
}

export const APICall = async (apiType: APIType, payload?: any) => {
  const { route, method } = apiType;
  const response = await fetch(route, {
    ...defaultFetchOptions,
    method,
    body: payload && JSON.stringify(payload),
  });
  if (response.status > 400) {
    let err;
    try {
      err = await response.json();
    } catch (e) {
      throw new Error(`Error ${response.status}`);
    }
    throw new Error(err.message);
  }
  return response;
};

type APIType = {
  method: MethodEnum;
  route: string;
};

export const APIRoutes = {
  fetchUser: () => ({
    method: MethodEnum.GET,
    route: `${getAPIEndpoint()}/profile`,
  }),
  login: () => ({
    method: MethodEnum.POST,
    route: `${getAPIEndpoint()}/auth/login`,
  }),
  signup: () => ({
    method: MethodEnum.POST,
    route: `${getAPIEndpoint()}/auth/signup`,
  }),
  syncSites: () => ({
    method: MethodEnum.GET,
    route: `${getAPIEndpoint()}/sites/sync`,
  }),
  getSites: () => ({
    method: MethodEnum.GET,
    route: `${getAPIEndpoint()}/sites`,
  }),
  getProducts: (siteId) => ({
    method: MethodEnum.GET,
    route: `${getAPIEndpoint()}/sites/${siteId}/products`,
  }),
  updateProductRecommendations: (siteId, productId) => ({
    method: MethodEnum.PUT,
    route: `${getAPIEndpoint()}/sites/${siteId}/products/${productId}/relatedProducts`,
  }),
  createTeam: () => ({
    method: MethodEnum.POST,
    route: `${getAPIEndpoint()}/teams`,
  }),
};
