import { configureStore } from "@reduxjs/toolkit";

import productsSlice from "./slices/productsSlice";
import sitesSlice from "./slices/sitesSlice";
import themeSlice from "./slices/themeSlice";
import userSlice from "./slices/userSlice";

const store = configureStore({
  reducer: {
    user: userSlice,
    theme: themeSlice,
    sites: sitesSlice,
    products: productsSlice,
  },
});

export default store;

export type AppDispatch = typeof store.dispatch;
