import Head from "next/head";
import { FC } from "react";

import siteMetadata from "../../../lib/siteMetadata";

type SEOProps = {
  title?: string;
  description?: string;
};

const SEO: FC<SEOProps> = ({ title, description }) => {
  const JSON_LD = {
    "@context": "http://schema.org",
    "@type": "Organization",
    name: "Shopirise",
    email: "info@shopirise.com",
    logo: "https://shopirise.com/images/logo.svg",
    url: "https://shopirise.com",
    description: description || siteMetadata.defaultDescription,
  };
  return (
    <Head>
      <title>{title || siteMetadata.defaultTitle}</title>
      <meta
        name="description"
        content={description || siteMetadata.defaultDescription}
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(JSON_LD),
        }}
      />
      <link rel="icon" href="/favicon.ico" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#2567f6" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/manifest.json" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
    </Head>
  );
};

export default SEO;
