import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";

import { APICall, APIRoutes } from "../../lib/APIUtils";

export const fetchSites = createAsyncThunk("sites/fetch", async () => {
  const response = await APICall(APIRoutes.getSites());
  return response.json();
});

export const syncSites = createAsyncThunk("sites/sync", async () => {
  const response = await APICall(APIRoutes.syncSites());
  return response.json();
});

export const changeCurrentSite = createAction("CURRENT_SITE_CHANGED");

export const sitesSlice = createSlice({
  name: "sites",
  initialState: {
    isLoadingSites: false,
    sites: null,
    error: null,
    currentSite: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSites.fulfilled, (state, action) => ({
        sites: action.payload,
        isLoadingSites: false,
        error: null,
        currentSite: 0,
      }))
      .addCase(fetchSites.pending, (state) => ({
        isLoadingSites: true,
        sites: state.sites,
        error: state.error,
        currentSite: 0,
      }))
      .addCase(fetchSites.rejected, (state, action) => ({
        error: action.error.message,
        sites: null,
        isLoadingSites: false,
        currentSite: 0,
      }))
      .addCase(changeCurrentSite, (state, action) => ({
        ...state,
        currentSite: action.payload,
      }))
      .addDefaultCase((state) => state);
  },
});

export const getSites = (state) => state && state.sites;
export const getCurrentSite = (state) =>
  state?.sites?.sites && state?.sites?.sites[state.sites.currentSite];

export default sitesSlice.reducer;
