import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { APICall, APIRoutes } from "../../lib/APIUtils";

export const fetchProducts = createAsyncThunk(
  "products/fetch",
  async (siteId: string) => {
    const response = await APICall(APIRoutes.getProducts(siteId));
    return response.json();
  }
);

export const updateProductRecommendations = createAsyncThunk(
  "sites/updateProductRecommendations",
  async (params: {
    siteId: string;
    productId: string;
    recommendedProductIds: string[];
  }) => {
    const response = await APICall(
      APIRoutes.updateProductRecommendations(params.siteId, params.productId),
      {
        relatedProducts: params.recommendedProductIds,
      }
    );
    return response.json();
  }
);

export const productsSlice = createSlice({
  name: "products",
  initialState: { products: null, isLoadingProducts: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.fulfilled, (state, action) => ({
        products: action.payload,
        isLoadingProducts: false,
        error: null,
      }))
      .addCase(fetchProducts.pending, (state) => ({
        products: state.products,
        isLoadingProducts: true,
        error: state.error,
      }))
      .addCase(fetchProducts.rejected, (state, action) => ({
        error: action.error.message,
        products: null,
        isLoadingProducts: false,
      }))
      .addCase(updateProductRecommendations.fulfilled, (state, action) => ({
        ...state,
        products: state.products?.map((product) => {
          if (product.id === action.meta.arg.productId) {
            return {
              ...action.payload,
              isLoading: false,
            };
          }
          return product;
        }),
      }))
      .addCase(updateProductRecommendations.pending, (state, action) => ({
        ...state,
        products: state.products?.map((product) => {
          if (product.id === action.meta.arg.productId) {
            return {
              ...product,
              isLoading: true,
            };
          }
          return product;
        }),
      }))
      .addCase(updateProductRecommendations.rejected, (state, action) => ({
        ...state,
        products: state.products?.map((product) => {
          if (product.id === action.meta.arg.productId) {
            return {
              ...product,
              isLoading: true,
              error: action.error.message,
            };
          }
          return product;
        }),
      }))
      .addDefaultCase((state) => state);
  },
});

export const getProducts = (state) => state && state.products;

export default productsSlice.reducer;
