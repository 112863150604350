import { createSlice } from "@reduxjs/toolkit";

export const themeSlice = createSlice({
  name: "theme",
  initialState: "light",
  reducers: {
    changeTheme(state, action) {
      // eslint-disable-next-line no-param-reassign
      return action.payload;
    },
  },
});

export const getTheme = (state) => state.theme;

export const { changeTheme } = themeSlice.actions;
export default themeSlice.reducer;
