import fullStory from "@analytics/fullstory";
import googleAnalytics from "@analytics/google-analytics";
import googleTagManager from "@analytics/google-tag-manager";
import Analytics from "analytics";

import { isServer } from "./isServer";

let analytics: any | undefined;
let amplitude;

function facebookPlugin() {
  const fbTrackEvents = [
    "AddPaymentInfo",
    "AddToCart",
    "AddToWishlist",
    "CompleteRegistration",
    "Contact",
    "CustomizeProduct",
    "Donate",
    "FindLocation",
    "InitiateCheckout",
    "Lead",
    "PageView",
    "Purchase",
    "Schedule",
    "Search",
    "StartTrial",
    "SubmitApplication",
    "Subscribe",
    "ViewContent",
  ];
  return {
    name: "facebook-pixel",
    initialize: () => {
      // loaded via GTM
    },
    page: () => {
      if (isServer()) return;
      const { fbq } = window as any;
      fbq("track", "PageView");
    },
    track: ({ payload }) => {
      if (isServer()) return;
      const { fbq } = window as any;
      if (fbTrackEvents.indexOf(payload.event) !== -1) {
        fbq("track", payload.event, payload.options);
      } else {
        fbq("trackCustom", payload.event, payload.options);
      }
    },
    loaded: () => {
      if (isServer()) return true;
      const { fbq } = window as any;
      return !!fbq;
    },
  };
}

function amplitudePlugin(config) {
  return {
    name: "amplitude",
    config,
    initialize: () => {
      if (isServer()) return;
      amplitude
        ?.getInstance()
        .init(config.id, null, { includeReferrer: true, includeUtm: true });
    },
    identify: ({ payload }) => {
      if (isServer()) return;
      amplitude?.getInstance().setUserId(payload.userId);
      const identify = new amplitude.Identify().set("user", payload.traits);
      amplitude?.getInstance().identify(identify);
      const org = payload.traits.organization;
      amplitude?.getInstance().setGroup("organization", org.domain);
    },
    page: ({ payload }) => {
      if (isServer()) return;
      amplitude?.getInstance().logEvent("PageView", payload);
    },
    track: ({ payload }) => {
      if (isServer()) return;
      amplitude?.getInstance().logEvent(payload.event, payload.properties);
    },
    loaded: () => {
      if (isServer()) return true;
      return !!amplitude?.getInstance();
    },
  };
}

export const trackPageView = () => {
  analytics?.page();
};

export const identifyUserAnalytics = (user) => {
  if (user) {
    analytics?.identify(user.id, user);
  }
};

export const signUp = (opts) => {
  analytics?.track("SignUp", opts);
  analytics?.track("Lead");
};

export const logOut = () => {
  analytics?.track("LogOut");
  amplitude?.getInstance().setUserId(null);
  amplitude?.getInstance().regenerateDeviceId();
};

export const setupAnalytics = () => {
  import("amplitude-js").then((amplitudeJs) => {
    amplitude = amplitudeJs;

    const plugins =
      process.env.NODE_ENV === "development"
        ? [
            amplitudePlugin({
              id: "856350ccab50d4ba21e4c9b50f9a7937",
            }),
          ]
        : [
            googleAnalytics({
              trackingId: "UA-167425978-1",
            }),
            googleTagManager({
              containerId: "GTM-5M54M97",
            }),
            fullStory({
              org: "VDTBY",
            }),
            facebookPlugin(),
            amplitudePlugin({
              id: "5edf0ce0ccb7f85a4c303876332566aa",
            }),
          ];
    analytics = Analytics({
      app: "Shopirise",
      plugins,
    });
  });
};
