import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { APICall, APIRoutes } from "../../lib/APIUtils";
import { syncSites } from "./sitesSlice";

export const fetchUser = createAsyncThunk("user/fetch", async () => {
  const response = await APICall(APIRoutes.fetchUser());
  return response.json();
});

type LoginPayload = {
  email: string;
  password: string;
};

export const login = createAsyncThunk(
  "user/login",
  async (payload: LoginPayload) => {
    const { email, password } = payload;
    const response = await APICall(APIRoutes.login(), { email, password });
    return response.json();
  }
);

type SignupPayload = {
  displayName: string;
  email: string;
  password: string;
};

export const signup = createAsyncThunk(
  "user/signup",
  async (payload: SignupPayload) => {
    const { displayName, email, password } = payload;
    const response = await APICall(APIRoutes.signup(), {
      displayName,
      email,
      password,
    });
    return response.json();
  }
);

type CreateTeamPayload = {
  teamName: string;
};

export const createTeam = createAsyncThunk(
  "user/createTeam",
  async (payload: CreateTeamPayload) => {
    const { teamName } = payload;
    const response = await APICall(APIRoutes.createTeam(), {
      teamName,
    });
    return response.json();
  }
);

type userStore = {
  isLoadingUser?: boolean;
  isCreatingTeam?: boolean;
  user: any;
  error?: any;
  createTeamError?: any;
};

const initialState = {
  isLoadingUser: false,
  isCreatingTeam: false,
  user: null,
  error: null,
  createTeamError: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState as userStore,
  reducers: {
    clearUser() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => ({
        isLoadingUser: false,
        user: action.payload,
      }))
      .addCase(fetchUser.pending, () => ({
        isLoadingUser: true,
        user: null,
      }))
      .addCase(fetchUser.rejected, (state, action) => ({
        isLoadingUser: false,
        user: null,
        error: action.error.message,
      }))
      .addCase(login.fulfilled, (state, action) => ({
        isLoadingUser: false,
        user: action.payload,
      }))
      .addCase(login.pending, () => ({
        isLoadingUser: true,
        user: null,
      }))
      .addCase(login.rejected, (state, action) => ({
        isLoadingUser: false,
        user: null,
        error: action.error.message,
      }))
      .addCase(signup.fulfilled, (state, action) => ({
        isLoadingUser: false,
        user: action.payload,
      }))
      .addCase(signup.pending, () => ({
        isLoadingUser: true,
        user: null,
      }))
      .addCase(signup.rejected, (state, action) => ({
        isLoadingUser: false,
        user: null,
        error: action.error.message,
      }))
      .addCase(createTeam.fulfilled, (state, action) => ({
        isCreatingTeam: false,
        user: {
          ...state.user,
          team: action.payload,
        },
      }))
      .addCase(createTeam.pending, (state) => ({
        isCreatingTeam: true,
        user: state.user,
      }))
      .addCase(createTeam.rejected, (state, action) => ({
        isCreatingTeam: false,
        user: state.user,
        createTeamError: action.error.message,
      }))
      .addCase(syncSites.fulfilled, (state, action) => ({
        isCreatingTeam: false,
        user: {
          ...state.user,
          team: {
            ...state.user.team,
            lastSyncAt: action.payload.lastSyncAt,
          },
        },
      }))
      .addDefaultCase((state) => state);
  },
});

export const { clearUser } = userSlice.actions;

export const getUser = (state) => state && state.user?.user;
export const getUserState = (state) => state && state.user;

export default userSlice.reducer;
